@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "General Sans";
  src: local("General Sans"),
    url("./fonts/General_Sans/GeneralSans-Regular.otf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "General Sans";
  src: local("General Sans"),
    url("./fonts/General_Sans/GeneralSans-Medium.otf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "General Sans";
  src: local("General Sans"),
    url("./fonts/General_Sans/GeneralSans-Semibold.otf") format("truetype");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: "General Sans", "General Sans Variable", sans-serif,
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
  @apply bg-evowy-light;
}
/* @src/components/Sidebar.module.css */

/* @layer components {
  .sidenav {
    @apply relative left-0 top-0 flex-col flex justify-between h-screen px-3 pb-10 pt-5 bg-gray-900 text-start min-w-[200px] max-w-[240px] md:max-w-[300px] md:min-w-[250px] text-gray-400 w-1/2 md:w-2/6 lg:w-2/12 border-r-[1px] border-gray-600 shadow-md shadow-gray-600;
} */
