.swal-confirm-button {
  width: 100%;
  height: 50px;
  min-width: 100%;
  padding: 10px;
  background-color: #31b9a9 !important;
  color: white;
  min-width: 50%;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: 0.3s;
  border: none;
  min-width: 100px;
}

.swal-cancel-button {
  width: 100%;
  height: 50px;
  padding: 10px;
  min-width: 100%;
  background-color: white;
  color: #31b9a9;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: 0.3s;
  border: 2px solid #31b9a9;
  min-width: 100px;
}

.swal-confirm-button:focus {
  outline: none;
}
.swal-cancel-button:focus {
  outline: none;
}

.swal-title-styled {
  font-size: 30px;
}

.swal-title-styled-small {
  font-size: 22px;
}
.swal2-html-container {
  font-weight: 400;
}

.swal-html-video {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swal-closebutton-styled {
  font-size: 35px;
}

.swal-popup-styled {
  font-size: 16px !important;
  padding: 1rem;
  border-radius: 20px;
}

.swal-popup-styled-video {
  font-size: 16px !important;
  padding: 1rem;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto !important;
}

.swal-confirm-button:hover,
.swal-cancel-button:hover {
  transform: scale(1.04);
}

.swal-action {
  flex-wrap: nowrap;
  width: 100%;
  gap: 2rem;
  padding: 0rem 2rem;
}

.swal-action-same {
  flex-direction: row;
}
