.styledSpan{
    position: relative;
    top: 10px;
    left: 20px;
    width: fit-content;
    background: rgb(236 235 255);
    padding: 0 5px;
    color: #31B9A9;
}

.styledImage{
    position:absolute;
    width: 38%;
    bottom: -30%;
    left: 2%;
    z-index: 0;
}

.styledImage2{
    position:absolute;
    width: 30%;
    right: 2%;
    z-index: 0;
}
